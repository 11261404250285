.block--twdm-contact-company-block .block-content {
  display: flex;
  flex-direction: column;
}

.block--twdm-contact-company-block .contact-item.phone_1:before, .block--twdm-contact-company-block .contact-item.email:before, .block--twdm-contact-company-block .contact-item.vat:before {
  font-weight: 700;
  margin-right: 15px;
  color: #FFD400;
}

.block--twdm-contact-company-block .contact-item.phone_1 {
  order: 10;
}

.block--twdm-contact-company-block .contact-item.phone_1:before {
  content: 't';
}

.block--twdm-contact-company-block .contact-item.email {
  order: 11;
}

.block--twdm-contact-company-block .contact-item.email:before {
  content: 'e';
}

.block--twdm-contact-company-block .contact-item.vat {
  order: 12;
  margin-top: 15px;
}

.block--twdm-contact-company-block .contact-item.vat:before {
  content: 'btw';
}

.block--twdm-contact-company-block .contact-item.postal_code {
  margin-bottom: 15px;
}

@media screen and (min-width: 992px) {
  .block--twdm-contact-company-block .contact-item.postal_code {
    margin-bottom: 25px;
  }
  .block--twdm-contact-company-block .contact-item.vat {
    margin-top: 25px;
  }
}
