@import "./_sass-essentials/essentials.scss";

.block--twdm-contact-company-block {
  .block-content {
    display: flex;
    flex-direction: column;
  }

  .contact-item {
    &.phone_1,
    &.email,
    &.vat {
      &:before {
        font-weight: $fw-bold;
        margin-right: 15px;
        color: $c-brand-primary;
      }
    }

    &.phone_1 {
      order: 10;

      &:before {
        content: 't';
      }
    }

    &.email {
      order: 11;

      &:before {
        content: 'e';
      }
    }

    &.vat {
      order: 12;
      margin-top: 15px;

      &:before {
        content: 'btw';
      }
    }

    &.postal_code {
      margin-bottom: 15px;
    }
  }

  @include mq($bp-md) {
    .contact-item {
      &.postal_code {
        margin-bottom: 25px;
      }

      &.vat {
        margin-top: 25px;
      }
    }
  }
}
